// Packages or third-party libraries
import React, { FC, ReactElement } from "react";
import { Input, InputError, ToggleSwitch } from "@epignosis_llc/gnosis";
import { CatalogSVG, GroupsSVG, ShareSVG } from "@epignosis_llc/gnosis/icons";
import { Controller } from "react-hook-form";

// Components
import TabWrapper from "../../components/TabWrapper";
import TabFormField from "../../components/TabFormField";
import ShareOption from "./components/ShareOption/ShareOption";

// Utils, hooks
import { useApplyTranslations } from "@hooks";

// Other imports
import { TabProps } from "../../types";

const AvailabilityTab: FC<TabProps> = ({ course, form }) => {
  const { t } = useApplyTranslations();

  const { policies } = course ?? {};
  const { can_share: canShare = false } = policies ?? {};

  const {
    control,
    formState: { errors },
  } = form;

  return (
    <TabWrapper>
      <TabFormField
        text={t("courseEdit.catalogVisibility")}
        icon={CatalogSVG}
        note={t("courseEdit.options.catalogNote")}
      >
        <Controller
          name="is_hidden_from_catalog"
          control={control}
          render={({ field: { onChange, value } }): ReactElement => {
            return (
              <ToggleSwitch
                data-testid="show-in-catalog"
                labelAfter={t("courseEdit.showCourseCatalog")}
                onChange={(): void => onChange(!value)}
                defaultChecked={!value}
              />
            );
          }}
        />
      </TabFormField>

      <TabFormField
        text={t("general.capacity")}
        icon={GroupsSVG}
        note={t("courseEdit.options.capacityNote")}
        hasDivider={canShare}
      >
        <>
          <Controller
            name="capacity"
            control={control}
            render={({ field: { onChange, value } }): JSX.Element => (
              <Input
                id="capacity"
                type="number"
                value={value ?? undefined}
                min={1}
                step={1}
                aria-label={t("general.capacity")}
                placeholder={t("general.setMaxNumber")}
                status={errors.capacity ? "error" : "valid"}
                onChange={(e): void => {
                  const capacity = e.target.value;
                  onChange(capacity ? capacity : null);
                }}
              />
            )}
          />
          {errors.capacity && <InputError>{errors.capacity.message}</InputError>}
        </>
      </TabFormField>

      {canShare && (
        <TabFormField
          text={t("courseEdit.publicSharing")}
          icon={ShareSVG}
          note={t("courseEdit.options.shareNote")}
          colSpan={2}
          hasDivider={false}
        >
          <ShareOption course={course} />
        </TabFormField>
      )}
    </TabWrapper>
  );
};

export default AvailabilityTab;
