import { CertificateDuration, CertificateReassignWhen } from "types/entities";
import { yup } from "../validation";
import { t } from "@utils/i18n";

const categoryObject = yup
  .object()
  .shape({
    name: yup.string().trim().max(80).nullable(),
    id: yup.number().min(1).nullable(),
  })
  .nullable();

const durationDaysSchema = yup.number().min(1);
const expirationMonthSchema = yup.number().min(1).max(12);
const expirationDaySchema = yup.number().min(1).max(31);

export const CoursesOptionsSchemas = yup.object().shape({
  code: yup.string().max(20).nullable(),
  category: categoryObject,
  price: yup.number().nullable().min(0.01).max(9999999999).maxDigitsAfterDecimal(2),
  is_hidden_from_catalog: yup.boolean(),
  intro_video_url: yup.string().max(255).nullable().isValidYoutubeOrVimeoUrl(),
  capacity: yup.number().min(1).nullable(),
  level: yup.number().min(1).max(20).nullable(),
  is_active: yup.boolean(),
  time_limit: yup.number().min(1).max(100000).nullable(),
  retain_access_after_completion: yup.boolean(),
  starts_at: yup.string().nullable(),
  expires_at: yup.string().nullable(),
  certificate: yup
    .object()
    .nullable()
    .shape({
      id: yup.number().required().min(1),
      duration_type: yup.string().required(),
      duration_days: durationDaysSchema.when("duration_type", {
        is: (val: string) => val === CertificateDuration.Custom,
        then: durationDaysSchema.required(),
      }),
      expiration_month: expirationMonthSchema.when("duration_type", {
        is: (val: string) => val === CertificateDuration.Date,
        then: expirationMonthSchema.required(),
      }),
      expiration_day: expirationDaySchema.when("duration_type", {
        is: (val: string) => val === CertificateDuration.Date,
        then: expirationDaySchema.required(),
      }),
      reassign_when: yup
        .string()
        .nullable()
        .when("duration_type", {
          is: (val: string) => val === CertificateDuration.OneMonth,
          then: yup
            .string()
            .nullable()
            .test(
              "oneMonthBeforeExpiration",
              () => t("courseEdit.validationMessages.oneMonthBeforeExpiration"),
              (value) => {
                return value !== CertificateReassignWhen.OneMonthBeforeExpiration;
              },
            ),
        }),
    }),
});

const learningPathObj = yup.object().shape({
  rule_set: yup.number().required(),
  courses: yup.array().min(1),
});

export const CourseRulesOptionsSchemas = yup.object().shape({
  traversal_rule: yup.string().required(),
  completion_rule: yup.string().required(),
  completion_percentage: yup
    .number()
    .nullable()
    .when("completion_rule", {
      is: (val: string) => val === "unit_percentage",
      then: yup.number().required().min(0.1).max(99.9).nullable(),
    }),
  completion_unit_ids: yup
    .array()
    .nullable()
    .when("completion_rule", {
      is: (val: string) => ["certain_units", "passed_test"].includes(val),
      then: yup.array().min(1).required(),
    }),
  score_rule: yup.string().required(),
  score_units: yup
    .array()
    .nullable()
    .when("score_rule", {
      is: (val: string) => val === "specific_units",
      then: yup.array().min(1).required(),
    }),
  learning_paths_obj: yup.array().of(learningPathObj).min(0).max(4).nullable(),
});
