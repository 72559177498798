import { URLS } from "@constants/urls";
import React from "react";
import { t } from "@utils/i18n";
import { Course } from "types/entities";
import { OtherProvidersCourse, OtherProvidersCourses } from "types/entities/Courses";
import { PlusIconSVG, MyCoursesSVG } from "@epignosis_llc/gnosis/icons";

type GetCourseLinkParams = {
  link?: string;
  courseId: string;
};

type ButtonColor =
  | "primary"
  | "secondary"
  | "danger"
  | "success"
  | "primaryLight"
  | "primaryDarker"
  | "white";

export const getCourseLink = ({ link, courseId }: GetCourseLinkParams): string => {
  return link ? link : URLS.catalog.createCourseLink({ courseId });
};

export const isOtherProviderCourses = (
  course: Course | OtherProvidersCourses,
): course is OtherProvidersCourses => {
  return "has_course" in course;
};

export const isOtherProviderCourse = (course: Course): course is OtherProvidersCourse => {
  return "provider" in course;
};

export const getCardBodyActionButtonProperties = (
  isCourseAcquired: boolean,
  showSubscriptionOnCard: boolean,
  showLicenses: boolean,
  showActiveSubscription: boolean,
  shouldShowReverseTrialTooltip: boolean,
): {
  actionButtonText: string;
  actionButtonIcon: JSX.Element;
  actionBtnColor: ButtonColor;
  className?: string;
} => {
  if (showLicenses) {
    return {
      actionButtonText: t("courseCatalog.buyLicenses"),
      actionButtonIcon: <PlusIconSVG height={32} />,
      actionBtnColor: "primary",
    };
  }

  if (isCourseAcquired) {
    return {
      actionButtonText: t("courseCatalog.youHaveCourse"),
      actionButtonIcon: <MyCoursesSVG height={32} />,
      actionBtnColor: "success",
      className: "is-owned",
    };
  }

  if (showSubscriptionOnCard) {
    return {
      actionButtonText: t("general.subscribe"),
      actionButtonIcon: <PlusIconSVG height={32} />,
      actionBtnColor: "primary",
    };
  }

  if (showActiveSubscription) {
    return {
      actionButtonText: t("payments.subscriptions.addToSubscription"),
      actionButtonIcon: <PlusIconSVG height={32} />,
      actionBtnColor: "primary",
    };
  }

  return {
    actionButtonText: shouldShowReverseTrialTooltip
      ? t("reverseTrial.talentLibraryPlusTooltip")
      : t("courseCatalog.getCourse"),
    actionButtonIcon: <PlusIconSVG height={32} />,
    actionBtnColor: "primary",
  };
};
