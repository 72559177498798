import React, { FC, useEffect, useRef, useState } from "react";
import FroalaEditor from "react-froala-wysiwyg";
import ReactFroalaEditor from "froala-editor";
import { Label } from "@epignosis_llc/gnosis";
import { SerializedStyles } from "@emotion/react";
import { useClickAway } from "ahooks";
import PubSub from "pubsub-js";
import { isEqual, sortBy } from "lodash";

// Plugins
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/js/plugins/code_view.min.js";
import "froala-editor/js/plugins/font_family.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/paragraph_format.min.js";
import "froala-editor/js/plugins/table.min.js";
import "froala-editor/js/plugins/line_height.min.js";
import "froala-editor/js/plugins/quote.min.js";
import "froala-editor/js/plugins/save.min.js";
import "froala-editor/js/plugins/quick_insert.min.js";

// CSS
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

// Styles
import { EditorStyles } from "./styles";

// Utils
import { insertImageBadLInkMessage } from "./helpers";
import { useLoadScript } from "@hooks";
import { i18n } from "@utils/i18n";

// Other imports
import {
  DEFAULT_CONFIGURATION,
  DEFAULT_IMAGE_INSERT_BUTTONS,
  FONTAWESOME_KIT_URL,
  INSERT_IMAGES_DROPDOWN,
} from "./constants";
import {
  AIEditorOptions,
  AllAIGenerateTextActions,
  InsertCustomOptions,
  ToolbarButton,
} from "./types";
import "./config";
import AIDropdownList from "@components/FormElements/Editor/AIDropdownList";
import { useAIActions } from "@components/FormElements/Editor/AI/AIActions";
import { t } from "i18next";
import { AIPromptModal } from "@components/AI/AIPromptModal/AIPromptModal";
import { createPortal } from "react-dom";
import { useConfigurationStore } from "@stores";

export type EditorProps = {
  toolbarButtons: string[] | ToolbarButton;
  id: string;
  model: string;
  placeholderText?: string;
  minHeight?: number;
  heightMax?: number;
  label?: string;
  status?: "valid" | "error";
  autofocus?: boolean;
  toolbarInline?: boolean;
  saveInterval?: number;
  required?: boolean;
  insertImagesOptions?: InsertCustomOptions;
  aiEditorOptions?: AIEditorOptions;
  uploadImageSubscriber?: string;
  uploadImageCloseSubscriber?: string;
  smartTagInsertSubscriber?: string;
  smartTagCloseSubscriber?: string;
  quickInsertEnabled?: boolean;
  onChange?: (html: string) => void;
  onBlur?: () => void;
  onSave?: (html: string) => void;
  onCodeViewToggle?: (isActive: boolean) => void;
  onFocus?: (isFocused: boolean) => void;
  onUploadImageButtonClick?: () => void;
  onInsertSmartTagButtonClick?: () => void;
  onAIStatusChanged?: (isWorking: boolean) => void;
};

const Editor: FC<EditorProps> = ({
  toolbarButtons,
  id,
  placeholderText = "",
  minHeight = 150,
  heightMax,
  label,
  required = false,
  status = "valid",
  model = "",
  autofocus = false,
  toolbarInline = false,
  // Note: It is NOT recommended to use values lower than 2000ms in order to prevent overload
  saveInterval = 0,
  insertImagesOptions,
  aiEditorOptions,
  uploadImageSubscriber,
  uploadImageCloseSubscriber,
  smartTagInsertSubscriber,
  smartTagCloseSubscriber,
  quickInsertEnabled = false,
  onChange,
  onBlur,
  onSave,
  onCodeViewToggle,
  onFocus,
  onUploadImageButtonClick,
  onInsertSmartTagButtonClick,
  onAIStatusChanged,
}): JSX.Element => {
  // Froala editor API is not typed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editorInstanceRef = useRef<ReactFroalaEditor | null>(null);
  const { isLoaded: isFontAwesomeKitLoaded } = useLoadScript(FONTAWESOME_KIT_URL);
  const [isFocused, setIsFocused] = useState(false);
  const editorRef = useRef<HTMLDivElement>(null);
  const editorSnapshotRef = useRef<string | null>(null);
  const [isUploadImageOpen, setIsUploadImageOpen] = useState(false);
  const [isInsertSmartTagOpen, setIsInsertSmartTagOpen] = useState(false);

  const writeAboutSubscriber = "write-about-subscriber";
  const [isWriteAboutModalOpen, setIsWriteAboutModalOpen] = useState(false);

  const hasError = status === "error";
  const hasImageOptions = insertImagesOptions && Object.keys(insertImagesOptions).length > 0;
  const aiActions = useAIActions(id, editorInstanceRef, onAIStatusChanged);

  const { userProfileData } = useConfigurationStore();
  const { can_use_editor_ai_options = false } = userProfileData?.policies?.ai ?? {};

  const [showAiDropdown, setShowAiDropdown] = useState<boolean>(false);
  const aiDropdownRef = useRef<HTMLDivElement | null>(null);
  const aiOptions =
    can_use_editor_ai_options && aiEditorOptions
      ? aiEditorOptions
      : {
          aiEnabled: false,
        };

  const isAiPromptModalOpen =
    aiOptions.aiEnabled &&
    isWriteAboutModalOpen &&
    !aiOptions.disabledActions?.includes("completion");

  // If all froala actions in the dropdown are disabled we need to hide some things
  const allAIPermissionsAreDisabled =
    aiOptions.disabledActions &&
    isEqual(sortBy(AllAIGenerateTextActions), sortBy(aiOptions.disabledActions));
  if (
    (!aiOptions.aiEnabled || allAIPermissionsAreDisabled) &&
    typeof toolbarButtons === "object" &&
    "horizontalDivider" in toolbarButtons
  ) {
    toolbarButtons = {
      moreText: toolbarButtons["moreText"],
      horizontalDivider: toolbarButtons["horizontalDivider"],
      moreRich: toolbarButtons["moreRich"],
    };
  }

  const closeAiMenu = (): void => setShowAiDropdown(false);

  // Froala editor API is not typed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleControllerReady = (editor: ReactFroalaEditor): void => {
    editorInstanceRef.current = editor;
  };

  useEffect(() => {
    return () => {
      editorInstanceRef.current = null;
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === "Escape") {
        aiActions.cancel();
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [aiActions]);

  // Dynamically show/hide INSERT_IMAGES_DROPDOWN option from insert images buttons
  const imageInsertButtons = !hasImageOptions
    ? DEFAULT_IMAGE_INSERT_BUTTONS
    : [...DEFAULT_IMAGE_INSERT_BUTTONS, INSERT_IMAGES_DROPDOWN];

  if (hasImageOptions) {
    ReactFroalaEditor.DefineIcon("insertImagesDropdownIcon", {
      NAME: "folder-image",
      template: "my_fa",
    });
    ReactFroalaEditor.RegisterCommand(INSERT_IMAGES_DROPDOWN, {
      title: "Insert images dropdown",
      type: "dropdown",
      icon: "insertImagesDropdownIcon",
      options: insertImagesOptions,
      undo: true,
      focus: true,
      refreshAfterCallback: true,
      callback: function (_: string, val: string) {
        this.image.insert(val, false, {}, null);
      },
    });
  }

  if (uploadImageSubscriber) {
    const uploadImageButtonCallback = function (editor: ReactFroalaEditor): void {
      if (!onUploadImageButtonClick) return;

      // Trigger save selection in order to apply the proper selection markers in editor's html
      editor.selection.save();

      // Take an editor's snapshot, that includes editor's selection info
      editorSnapshotRef.current = editor.snapshot.get();

      onUploadImageButtonClick();
      setIsUploadImageOpen(true);

      // Define what happens once the image is uploaded
      const handleImageUpload = (token: string, imageUrl: string): void => {
        // Restore the previous saved snapshot
        editor.snapshot.restore(editorSnapshotRef.current);

        // Insert the new uploaded image
        editor.image.insert(imageUrl, false, {}, null);

        // Reset ref
        editorSnapshotRef.current = null;
        setIsUploadImageOpen(false);

        // Unsubscribe to ensure this callback isn't called multiple times
        PubSub.unsubscribe(token);
      };

      // Subscribe to all events of the given uploadImageSubscriber
      PubSub.subscribe(uploadImageSubscriber, handleImageUpload);

      if (uploadImageCloseSubscriber) {
        // Define what happens on image upload modal close
        PubSub.subscribe(uploadImageCloseSubscriber, () => setIsUploadImageOpen(false));
      }
    };

    ReactFroalaEditor.DefineIcon("uploadImageButtonIcon", {
      NAME: "cloud-arrow-up",
      template: "my_fa",
    });
    ReactFroalaEditor.RegisterCommand("uploadImageButton", {
      title: "Upload image",
      type: "button",
      icon: "uploadImageButtonIcon",
      undo: true,
      focus: true,
      refreshAfterCallback: true,
      callback: function () {
        uploadImageButtonCallback(this);
      },
    });

    if (quickInsertEnabled) {
      ReactFroalaEditor.RegisterQuickInsertButton("uploadImageButton", {
        title: "Upload image",
        icon: "uploadImageButtonIcon",
        callback: function () {
          const editor = this as ReactFroalaEditor;
          uploadImageButtonCallback(editor);
        },
      });
    }
  }

  if (smartTagInsertSubscriber) {
    const insertSmartTagsButtonCallback = (editor: ReactFroalaEditor): void => {
      if (!onInsertSmartTagButtonClick) return;

      // Trigger save selection in order to apply the proper selection markers in editor's html
      editor.selection.save();

      // Take an editor's snapshot, that includes editor's selection info
      editorSnapshotRef.current = editor.snapshot.get();

      onInsertSmartTagButtonClick();
      setIsInsertSmartTagOpen(true);

      // Define what happens on smart tag selection
      const handleSmartTagInsert = (token: string, smartTag: string): void => {
        // Restore the previous saved snapshot
        editor.snapshot.restore(editorSnapshotRef.current);

        // Insert the smart tag
        editor.html.insert(smartTag);

        // Reset ref
        editorSnapshotRef.current = null;
        setIsInsertSmartTagOpen(false);

        // Unsubscribe to ensure this callback isn't called multiple times
        PubSub.unsubscribe(token);
      };

      // Subscribe to all events of the given handleSmartTagInsert
      PubSub.subscribe(smartTagInsertSubscriber, handleSmartTagInsert);

      if (smartTagCloseSubscriber) {
        // Define what happens on smart tag modal close
        PubSub.subscribe(smartTagCloseSubscriber, () => setIsInsertSmartTagOpen(false));
      }
    };

    ReactFroalaEditor.DefineIcon("insertSmartTagIcon", {
      NAME: "tag",
      template: "my_fa",
    });
    ReactFroalaEditor.RegisterCommand("insertSmartTagButton", {
      title: "Smart tags",
      type: "button",
      icon: "insertSmartTagIcon",
      undo: true,
      focus: true,
      refreshAfterCallback: true,
      callback: function () {
        insertSmartTagsButtonCallback(this);
      },
    });

    if (quickInsertEnabled) {
      ReactFroalaEditor.RegisterQuickInsertButton("insertSmartTagButton", {
        title: "Smart tags",
        icon: "insertSmartTagIcon",
        callback: function () {
          const editor = this as ReactFroalaEditor;
          insertSmartTagsButtonCallback(editor);
        },
      });
    }
  }

  if (quickInsertEnabled || aiOptions.forceEnableWriteAbout === true) {
    if (aiOptions.aiEnabled && !aiOptions.disabledActions?.includes("completion")) {
      const aiInsertButtonCallback = (editor: ReactFroalaEditor): void => {
        editor.selection.save();
        setIsWriteAboutModalOpen(true);

        // Define what happens on smart tag selection
        const handleWriteAboutInsert = (token: string, prompt: string): void => {
          aiActions.insert(prompt, editor);

          setIsWriteAboutModalOpen(false);
          // Unsubscribe to ensure this callback isn't called multiple times
          PubSub.unsubscribe(token);
        };

        // Subscribe to all events of the given handleWriteAboutInsert
        PubSub.subscribe(writeAboutSubscriber, handleWriteAboutInsert);
      };

      ReactFroalaEditor.DefineIcon("magicWand", {
        NAME: "write-ai",
        template: "my_fa",
      });

      ReactFroalaEditor.RegisterQuickInsertButton("writeAbout", {
        title: "Write about",
        icon: "magicWand",
        // Froala editor API is not typed
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        callback: function (this: ReactFroalaEditor) {
          aiInsertButtonCallback(this);
        },
      });
    }
  }

  if (aiOptions.aiEnabled && !allAIPermissionsAreDisabled) {
    ReactFroalaEditor.DefineIcon("Sparkles", { NAME: "sparkles", template: "my_fa" });
    ReactFroalaEditor.RegisterCommand("aiFeatures", {
      title: "AI Features",
      icon: "Sparkles",
      toggle: true,
      callback: () => {
        setShowAiDropdown((show) => !show);
      },
    });
  }

  // On click outside fire blur callback
  // Used instead of Froala'a blur event and the reason behind this approach is
  // That image caption is firing blur event as well (probably Froala's bug).
  useClickAway((e) => {
    // Not fire blur when editor is not focused
    if (!isFocused) return;

    const target = e.target as HTMLElement;

    // Check if the target is a froala element because,
    // There some editor's pop-ups elements that shouldn't trigger blur
    // Eg. insert image and image pop-up
    const isFroalaElement = Array.from(target.classList).some((className) =>
      className.startsWith("fr"),
    );

    // Not trigger blur when upload image or insert smart tag modal is opened
    if (isUploadImageOpen || isInsertSmartTagOpen) return;

    if (!isFroalaElement) {
      setIsFocused(false);
      onFocus && onFocus(false);
      onBlur && onBlur();
    }
  }, editorRef);

  // Editor handled events
  const events = {
    initialized: function (this: ReactFroalaEditor): void {
      handleControllerReady(this);
    },
    focus: (): void => {
      setIsFocused(true);
      onFocus && onFocus(true);
    },
    "image.error": (error: { code: number }): void => {
      // Bad link error
      if (error.code === 1) {
        const editor = editorRef.current;
        const errorHeading = editor?.querySelector(".fr-image-progress-bar-layer h3");

        if (errorHeading) {
          errorHeading.innerHTML = insertImageBadLInkMessage();
        }
      }
    },
    // Will trigger only if saveInterval value is set > 0
    "save.before": function (html: string): void {
      onSave && onSave(html ?? "");
    },
    "commands.mousedown": function (btn: { 0?: HTMLElement }): void {
      let target: HTMLElement | null | undefined = btn[0];

      while (target && target instanceof HTMLElement) {
        if (target.hasAttribute("data-cmd") && target.getAttribute("data-cmd") === "aiFeatures") {
          return;
        }
        target = target.parentElement; // Move up the DOM tree
      }

      closeAiMenu();
    },
    "commands.after": function (cmd: string): void {
      if (cmd === "html") {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const isActive = (this as any)?.codeView?.isActive();
        onCodeViewToggle && onCodeViewToggle(isActive);
      }
    },
    "image.inserted": function (): void {
      // Hide loading image popup on image insertion
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this as any)?.popups?.hideAll();
    },
    "toolbar.hide": function (): void {
      closeAiMenu();
    },
  };

  // Final editor's configuration
  const config = {
    ...DEFAULT_CONFIGURATION,
    imageInsertButtons,
    toolbarButtons: toolbarButtons,
    placeholderText: placeholderText,
    heightMin: minHeight,
    heightMax: heightMax,
    toolbarInline: toolbarInline,
    direction: i18n.dir(),
    autofocus: autofocus,
    saveInterval: saveInterval,
    quickInsertEnabled:
      quickInsertEnabled ||
      (aiOptions?.forceEnableWriteAbout && !aiOptions.disabledActions?.includes("completion")),
    quickInsertButtons:
      aiOptions.forceEnableWriteAbout &&
      !quickInsertEnabled &&
      !aiOptions.disabledActions?.includes("completion")
        ? ["writeAbout"]
        : DEFAULT_CONFIGURATION.quickInsertButtons,
    events,
  };

  const handleModelChange = (object: string): void => {
    // Avoid unnecessary calls
    if (model === object) return;

    onChange && onChange(object);
  };

  const handleTextGeneration = (prompt: string): void => {
    PubSub.publish(writeAboutSubscriber, prompt);
    closeWriteAboutModal();
  };

  const closeWriteAboutModal = (): void => {
    setIsWriteAboutModalOpen(false);
  };

  useEffect(() => {
    const aiButton = document.querySelector('.fr-command[data-cmd="aiFeatures"]');
    if (aiButton) {
      if (showAiDropdown) {
        // Append fr-ai-btn-active class
        aiButton.classList.add("fr-ai-btn-active");
      } else {
        // Remove fr-ai-btn-active class
        aiButton.classList.remove("fr-ai-btn-active");
      }
    }
  }, [showAiDropdown]);

  useEffect(() => {
    const toolbars = document.querySelectorAll<HTMLElement>(".fr-toolbar");

    if (isAiPromptModalOpen) {
      toolbars.forEach((toolbar) => {
        toolbar.style.display = "none";
      });
    }
  }, [isAiPromptModalOpen]);

  return (
    <>
      <div
        id={id}
        ref={editorRef}
        css={(theme): SerializedStyles => EditorStyles(theme, { required, isFocused, hasError })}
      >
        {label && <Label>{label}</Label>}

        {isFontAwesomeKitLoaded && (
          <FroalaEditor
            tag="textarea"
            config={config}
            model={model}
            onModelChange={handleModelChange}
          />
        )}
      </div>
      <AIPromptModal
        id={id}
        isOpen={isAiPromptModalOpen}
        onClose={closeWriteAboutModal}
        header={t("ai.writeAbout")}
        onGenerate={handleTextGeneration}
      />
      {showAiDropdown &&
        aiOptions.aiEnabled &&
        createPortal(
          <AIDropdownList
            dropdownRef={aiDropdownRef}
            aiActions={aiActions}
            aiOptions={aiOptions}
            close={closeAiMenu}
          />,
          document.body,
        )}
    </>
  );
};

export default React.memo(Editor);
