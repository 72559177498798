import { HandledError } from "./errors";

export const genericErrors: HandledError[] = [
  {
    status: 401,
    id: "access_denied",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
];

export const groupErrors: HandledError[] = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
];

export const updateProfileErrors: HandledError[] = [
  {
    status: 400,
    id: "bad_request.email_field_username_recently_deleted",
    errorMsg: "signUp.validationMessages.emailWasRecentlyDeleted",
  },
  {
    status: 400,
    id: "bad_request.email_field_email_recently_deleted",
    errorMsg: "signUp.validationMessages.emailWasRecentlyDeleted",
  },
  {
    status: 400,
    id: "bad_request.username_field_username_recently_deleted",
    errorMsg: "signUp.validationMessages.usernameWasRecentlyDeleted",
  },
  {
    status: 400,
    id: "bad_request.username_field_email_recently_deleted",
    errorMsg: "signUp.validationMessages.usernameWasRecentlyDeleted",
  },
  {
    status: 400,
    id: "bad_request.email_updated_recently",
    errorMsg: "errors.profile.emailRecentlyUpdated",
  },
  {
    status: 400,
    id: "bad_request.user_avatar_upload_failed",
    errorMsg: "notifications.messages.errorUploadAvatar",
  },
  {
    status: 400,
    id: "bad_request.same_as_current_password",
    errorMsg: "profileSettings.modal.currentAndNewAreTheSame",
  },
  {
    status: 400,
    id: "bad_request.reuse_old_password",
    errorMsg: "profileSettings.modal.newPasswordReused",
  },
  {
    status: 400,
    id: "non_unique_user_username",
    errorMsg: "errors.profile.usernameAlreadyExist",
  },
  {
    status: 400,
    id: "non_unique_user_email",
    errorMsg: "errors.profile.emailAlreadyExist",
  },
  {
    status: 400,
    id: "bad_request.user_not_locked",
    errorMsg: "errors.profile.userNotLocked",
  },
  {
    status: 400,
    id: "bad_request.user_is_member_of_branch_that_reached_max_users_limit",
    errorMsg: "errors.profile.userCannotBeActivatedDueToBranchLimit",
  },
  {
    status: 403,
    id: "forbidden.reached_plan_max_users",
    errorMsg: "signUp.validationMessages.maxUsersReached",
  },
  {
    status: 403,
    id: "forbidden.reached_branch_max_users",
    errorMsg: "errors.users.reachedMaxBranchUsers",
  },
];

export const couponErrors: HandledError[] = [
  {
    status: 400,
    id: "bad_request.coupon_expired",
    errorMsg: "errors.coupons.couponExpired",
  },
  {
    status: 400,
    id: "bad_request.invalid_coupon",
    errorMsg: "errors.coupons.couponInvalid",
  },
];

export const userErrors: HandledError[] = [
  {
    status: 404,
    id: "not_found.user_not_found",
    errorMsg: "users.errors.notFound",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
];

export const userBranchesErrors: HandledError[] = [
  {
    status: 404,
    id: "not_found.user_not_found",
    errorMsg: "users.errors.notFound",
  },
  {
    status: 404,
    id: "not_found.branch_not_found",
    errorMsg: "users.errors.notFound",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 403,
    id: "forbidden.reached_branch_max_users",
    errorMsg: "branches.courses.errors.branchLimitReached",
  },
];

export const autologinErrors: HandledError[] = [
  {
    status: 401,
    id: "invalid_grant",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 403,
    id: "forbidden.user_required_to_update_password",
    errorMsg: "signIn.validationMessages.changePassword",
  },
  {
    status: 403,
    id: "forbidden.user_not_allowed_to_access_plus",
    errorMsg: "errors.branchSwitch.cannotAccessPlus",
  },
  {
    status: 403,
    id: "forbidden.cannot_login_to_main_portal",
    errorMsg: "signUp.validationMessages.restrictedToBranch",
  },
  {
    status: 403,
    id: "forbidden.plus_is_disabled",
    errorMsg: "signIn.validationMessages.usernameOrPassword",
  },
];

export const signInErrors: HandledError[] = [
  {
    status: 401,
    id: "unsupported_grant_type",
    errorMsg: "signIn.validationMessages.usernameOrPassword",
  },
  {
    status: 401,
    id: "invalid_grant",
    errorMsg: "signIn.validationMessages.usernameOrPassword",
  },
  {
    status: 401,
    id: "invalid_client",
    errorMsg: "signIn.validationMessages.usernameOrPassword",
  },
  {
    status: 403,
    id: "forbidden.plus_is_disabled",
    errorMsg: "signIn.validationMessages.usernameOrPassword",
  },
  {
    status: 403,
    id: "forbidden.user_not_allowed_to_access_plus",
    errorMsg: "signIn.validationMessages.usernameOrPassword",
  },
  {
    status: 403,
    id: "forbidden.cannot_login_to_main_portal",
    errorMsg: "signIn.validationMessages.restrictedToBranch",
  },
  {
    status: 403,
    id: "forbidden.locked_portal",
    errorMsg: "signIn.validationMessages.lockedPortal",
  },
  {
    status: 403,
    id: "forbidden.user_is_locked",
    errorMsg: "signIn.validationMessages.lockedUser",
  },
  {
    status: 403,
    id: "forbidden.user_not_member_in_branch",
    errorMsg: "signIn.validationMessages.notBranchMember",
  },
  {
    status: 403,
    id: "forbidden.user_required_to_update_password",
    errorMsg: "signIn.validationMessages.changePassword",
  },
  {
    status: 403,
    id: "forbidden.only_admins_can_login_on_cancelled_subscription",
    errorMsg: "signIn.validationMessages.onlyAdminsCancelledSubscription",
  },
  {
    status: 403,
    id: "forbidden.paused_portal",
    errorMsg: "accountAndSettings.security.defaultSystemLockMessage",
  },
  {
    status: 409,
    id: "conflict.user_not_active",
    errorMsg: "signIn.validationMessages.userNotActive",
  },
];

export const signUpErrors: HandledError[] = [
  ...signInErrors,
  {
    status: 403,
    id: "forbidden.signup_disabled",
    errorMsg: "signUp.validationMessages.signUpDisabled",
  },
  {
    status: 404,
    id: "not_found.branch_not_found",
    errorMsg: "signUp.validationMessages.branchNotFound",
  },
  {
    status: 403,
    id: "forbidden.reached_plan_max_users",
    errorMsg: "signUp.validationMessages.maxUsersReached",
  },
];

export const passwordChangeErrors: HandledError[] = [
  {
    status: 400,
    id: "bad_request.same_as_current_password",
    errorMsg: "profileSettings.modal.currentAndNewAreTheSame",
  },
  {
    status: 400,
    id: "bad_request.reuse_old_password",
    errorMsg: "profileSettings.modal.newPasswordReused",
  },
  {
    status: 400,
    id: "bad_request.password_update_token_invalid_or_expired",
    errorMsg: "signIn.expiredPasswordChangeToken",
  },
];

export const passwordCreateErrors: HandledError[] = [
  {
    status: 400,
    id: "bad_request.create_password_verification_code_is_invalid",
    errorMsg: "signIn.invalidLink",
  },
  {
    status: 400,
    id: "bad_request.create_password_verification_code_has_expired",
    errorMsg: "signIn.expiredLink",
  },
  {
    status: 400,
    id: "validation_error",
    errorMsg: "errors.somethingWentWrongPleaseTryAgain",
  },
];

export const noPermissionsError: HandledError[] = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
];

export const branchSwitchErrors: HandledError[] = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 403,
    id: "forbidden.user_not_allowed_to_access_plus",
    errorMsg: "errors.branchSwitch.cannotAccessPlus",
  },
  {
    status: 403,
    id: "forbidden.cannot_login_to_main_portal",
    errorMsg: "errors.branchSwitch.cannotLoginToMainPortal",
  },
  {
    status: 403,
    id: "forbidden.user_not_member_in_branch",
    errorMsg: "errors.branchSwitch.notMember",
  },
];

export const newMessageErrors = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 409,
    id: "conflict.invalid_message_recipient.recipients_not_found",
    errorMsg: "errors.messages.recipientNotFound",
  },
  {
    status: 409,
    id: "conflict.invalid_message_recipient.invalid_message_user_recipient",
    errorMsg: "errors.messages.invalidRecipientMessage",
  },
  {
    status: 409,
    id: "conflict.invalid_message_recipient.invalid_course_recipient",
    errorMsg: "errors.messages.invalidRecipientCourse",
  },
  {
    status: 409,
    id: "conflict.invalid_message_recipient.invalid_branch_recipient",
    errorMsg: "errors.messages.invalidRecipientBranch",
  },
  {
    status: 409,
    id: "conflict.invalid_message_recipient.invalid_group_recipient",
    errorMsg: "errors.messages.invalidRecipientGroup",
  },
];

export const discussionErrors: HandledError[] = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 409,
    id: "conflict",
    errorMsg: "errors.discussions.singleLevelNesting",
  },
];

export const invalidCouponErrors = [
  "bad_request.invalid_coupon",
  "bad_request.coupon_expired",
  "bad_request.updated_coupon",
];

export const paymentErrors: HandledError[] = [
  {
    status: 400,
    id: "bad_request.invalid_coupon",
  },
  {
    status: 400,
    id: "bad_request.coupon_expired",
  },
  {
    status: 400,
    id: "bad_request.updated_coupon",
  },
  {
    status: 402,
    id: "payment_required",
    errorMsg: "payments.errors.paymentRequired",
  },
  {
    status: 402,
    id: "payment_required.price_changed",
    errorMsg: "payments.errors.priceChanged",
  },
  { status: 429, id: "too_many_requests", errorMsg: "payments.errors.tooManyRequests" },
];

export const enrollmentErrors: HandledError[] = [
  {
    status: 402,
    id: "payment_required",
    errorMsg: "errors.enrollment.paymentRequired",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.enrollment.noPermissions",
  },
  {
    status: 409,
    id: "conflict.user_already_enrolled",
    errorMsg: "errors.enrollment.alreadyEnrolled",
  },
  {
    status: 403,
    id: "forbidden.terms_not_accepted",
    errorMsg: "", // We do not show error msg for this error
  },
];

export const subscriptionErrors: HandledError[] = [
  {
    status: 400,
    id: "bad_request",
    errorMsg: "errors.subscriptions.notEnabled",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 409,
    id: "conflict",
    errorMsg: "errors.subscriptions.alreadyInEffect",
  },
  { status: 429, id: "too_many_requests", errorMsg: "payments.errors.tooManyRequests" },
];

export const createThemeErrors: HandledError[] = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 400,
    id: "bad_request.invalid_theme_name",
    errorMsg: "errors.theme.invalidThemeName",
  },
  {
    status: 400,
    id: "bad_request.theme_name_already_exists",
    errorMsg: "errors.theme.nameAlreadyExists",
  },
  {
    status: 400,
    id: "bad_request.missing_or_invalid_theme_option",
    errorMsg: "errors.theme.missingOrInvalid",
  },
];

export const deleteThemeErrors: HandledError[] = [
  {
    status: 400,
    id: "bad_request.cannot_delete_default_theme",
    errorMsg: "errors.theme.cannotDelete",
  },
  {
    status: 403,
    id: "bad_request.invalid_theme_name",
    errorMsg: "errors.general.noPermissions",
  },
];

export const groupsErrors: HandledError[] = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 404,
    id: "not_found.group_not_found",
    errorMsg: "groups.courses.errors.groupNotFound",
  },
  {
    status: 404,
    id: "not_found.course_not_found",
    errorMsg: "groups.courses.errors.courseNotFound",
  },
];

export const branchesErrors: HandledError[] = [
  {
    status: 404,
    id: "not_found.branch_not_found",
    errorMsg: "branches.courses.errors.branchNotFound",
  },
  {
    status: 404,
    id: "not_found.course_not_found",
    errorMsg: "branches.courses.errors.courseNotFound",
  },

  {
    status: 403,
    id: "forbidden.reached_branch_max_users",
    errorMsg: "branches.courses.errors.branchLimitReached",
  },
];

export const userCoursesErrors: HandledError[] = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 404,
    id: "not_found.course_not_found",
    errorMsg: "errors.courses.notFound",
  },
  {
    status: 404,
    id: "not_found.certificate_not_found",
    errorMsg: "errors.courses.certificateNotFound",
  },
  {
    status: 404,
    id: "not_found.user_not_found",
    errorMsg: "users.errors.notFound",
  },
  {
    status: 409,
    id: "conflict.no_licences_available",
    errorMsg: "users.errors.noLicensesAvailable",
  },
];

export const userUnitsErrors: HandledError[] = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
];

export const userImpersonationErrors: HandledError[] = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 403,
    id: "forbidden.user_not_member_in_branch",
    errorMsg: "signIn.validationMessages.notBranchMember",
  },
  {
    status: 403,
    id: "forbidden.cannot_login_to_main_portal",
    errorMsg: "signIn.validationMessages.restrictedToBranch",
  },
  {
    status: 403,
    id: "forbidden.user_sso",
    errorMsg: "users.impersonate.validationMessages.userSSO",
  },
  {
    status: 403,
    id: "forbidden.sso_user",
    errorMsg: "users.impersonate.validationMessages.ssoUserImpersonated",
  },
];

export const joinGroupErrors: HandledError[] = [
  {
    status: 400,
    id: "bad_request.reached_limit_of_five_groups_per_minute",
    errorMsg: "myGroups.reachedMaximumGroupsPerMinute",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 404,
    id: "not_found.group_not_found",
    errorMsg: "myGroups.invalidKey",
  },
  {
    status: 409,
    id: "conflict.no_group_redemptions_left",
    errorMsg: "myGroups.keyHasExpired",
  },
  {
    status: 409,
    id: "conflict.user_already_enrolled",
    errorMsg: "myGroups.alreadyEnrolled",
  },
];

export const uploadFilesErrors: HandledError[] = [
  {
    status: 400,
    id: "bad_request",
    errorMsg: "general.uploadFileFailed",
  },
  {
    status: 400,
    id: "bad_request.invalid_url",
    errorMsg: "files.urlNotValid",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 404,
    id: "not_found.user_not_found",
    errorMsg: "users.errors.notFound",
  },
  {
    status: 404,
    id: "not_found.group_not_found",
    errorMsg: "groups.courses.errors.groupNotFound",
  },
  {
    status: 404,
    id: "not_found.branch_not_found",
    errorMsg: "branches.courses.errors.branchNotFound",
  },
  {
    status: 404,
    id: "not_found.file_not_found",
    errorMsg: "errors.files.fileNotFound",
  },
  {
    status: 404,
    id: "not_found.course_not_found",
    errorMsg: "errors.courses.notFound",
  },
  {
    status: 413,
    id: "file_too_large",
    errorMsg: "errors.files.uploadFileTooBig",
  },
  {
    status: 415,
    id: "unsupported_media_type",
    errorMsg: "errors.files.uploadFileUnsupported",
  },
];

export const previewCertificateTemplateErrors = [
  {
    status: 404,
    id: "not_found.certificate_template_not_found",
    errorMsg: "errors.certificateTemplate.notFound",
  },
];

export const portalSettingsErrors = [
  {
    status: 400,
    id: "file_too_large",
    errorMsg: "errors.general.uploadFileTooBig",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
];

export const domainNameErrors = [
  {
    status: 400,
    id: "non_unique_domain_name",
    errorMsg: "errors.portalSettings.uniqueDomainName",
  },
  {
    status: 400,
    id: "invalid_domain_name",
    errorMsg: "errors.portalSettings.invalidDomainName",
  },
  {
    status: 400,
    id: "unavailable_domain_name",
    errorMsg: "errors.portalSettings.unavailableDomainName",
  },
  {
    status: 400,
    id: "internal_domain_name",
    errorMsg: "errors.portalSettings.internalDomainName",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
];

export const customEmailErrors = [
  {
    status: 400,
    id: "bad_request.custom_domain_not_setup",
    errorMsg: "errors.portalSettings.customDomainNotSetup",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
];

export const customSSLErrors = [
  {
    status: 400,
    id: "bad_request.custom_domain_not_setup",
    errorMsg: "errors.portalSettings.customDomainNotSetup",
  },
  {
    status: 400,
    id: "bad_request.malformed_ssl_certificate",
    errorMsg: "errors.portalSettings.malformedSSLCertificate",
  },
  {
    status: 400,
    id: "bad_request.invalid_ssl_certificate",
    errorMsg: "errors.portalSettings.invalidSSLCertificate",
  },
  {
    status: 400,
    id: "bad_request.private_key_does_not_match_certificate",
    errorMsg: "errors.portalSettings.privateKeyDoesNotMatchCertificate",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 500,
    id: "server_error.failed_to_install_ssl_certificate",
    errorMsg: "errors.portalSettings.failedToInstallSSL",
  },
];

export const genericMassActionsErrors = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 404,
    id: "not_found.course_not_found",
    errorMsg: "errors.courses.notFound",
  },
];

export const groupUsersMassActionsErrors = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 404,
    id: "not_found.group_not_found",
    errorMsg: "groups.errors.groupNotFound",
  },
  {
    status: 409,
    id: "not_found.user_not_found",
    errorMsg: "users.errors.notFound",
  },
];

export const usersMassActionsErrors = [
  ...genericErrors,
  ...groupUsersMassActionsErrors,
  {
    status: 403,
    id: "forbidden.reached_branch_max_users",
    errorMsg: "errors.branches.maxReached",
  },
];

export const CSRKeyErrors = [
  {
    status: 400,
    id: "bad_request.custom_domain_not_setup",
    errorMsg: "errors.portalSettings.customDomainNotSetup",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 500,
    id: "server_error.csr_generation_failed",
    errorMsg: "errors.portalSettings.generateCSRFailed",
  },
  {
    status: 500,
    id: "server_error.private_key_generation_failed",
    errorMsg: "errors.portalSettings.privateKeyGenerationFailed",
  },
];

export const customDomainNameErrors = [
  {
    status: 400,
    id: "non_unique_domain_name",
    errorMsg: "errors.portalSettings.uniqueDomainName",
  },
  {
    status: 400,
    id: "invalid_domain_name",
    errorMsg: "errors.portalSettings.invalidDomainName",
  },
  {
    status: 400,
    id: "unavailable_domain_name",
    errorMsg: "errors.portalSettings.unavailableDomainName",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
];

export const autologinBranchErrors = [
  {
    status: 401,
    id: "access_denied",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 403,
    id: "forbidden.user_not_member_in_branch",
    errorMsg: "signIn.validationMessages.notBranchMember",
  },
  {
    status: 403,
    id: "forbidden.user_not_allowed_to_access_plus",
    errorMsg: "errors.branchSwitch.cannotAccessPlus",
  },
  {
    status: 403,
    id: "forbidden.only_admins_can_login_on_cancelled_subscription",
    errorMsg: "signIn.validationMessages.onlyAdminsCancelledSubscription",
  },
];

export const languageOverridesErrors = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 404,
    id: "not_found.language_override_not_found",
    errorMsg: "errors.usersSettings.languageOverrides.languageOverrideNotFound",
  },
];

export const previewCertificateErrors = [
  {
    status: 404,
    id: "not_found.certificate_not_found",
    errorMsg: "errors.certificate.notFound",
  },
];

export const affectedBranchesByMassActionsErrors = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 404,
    id: "not_found.course_not_found",
    errorMsg: "errors.courses.notFound",
  },
];

export const userTypesErrors = [
  {
    status: 401,
    id: "access_denied",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 404,
    id: "not_found.user_type_not_found",
    errorMsg: "users.errors.notFound",
  },
];

export const customUserFieldsErrors = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 403,
    id: "forbidden.reached_max_fields",
    errorMsg: "errors.usersSettings.usersSettings.maxFieldsExceed",
  },
];

export const cmsPagesErrors = [
  {
    status: 401,
    id: "access_denied",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 413,
    id: "file_too_small",
    errorMsg: "errors.customHomePage.exceedsMinimumSizeLimit",
  },
  {
    status: 500,
    id: "server_error.upload_failed",
    errorMsg: "errors.customHomePage.uploadFailed",
  },
  {
    status: 403,
    id: "forbidden.reached_section_maximum_featured_courses",
    errorMsg: "errors.customHomePage.sectionMaximumCoursesReached",
  },
  {
    status: 403,
    id: "forbidden.reached_page_maximum_sections",
    errorMsg: "errors.customHomePage.pageMaximumSections",
  },
  {
    status: 404,
    id: "not_found.page_not_found",
    errorMsg: "errors.customHomePage.customHomePageNotFound",
  },
  {
    status: 404,
    id: "not_found.section_not_found",
    errorMsg: "errors.customHomePage.customHomePageSectionNotFound",
  },
  { status: 404, id: "not_found.user_type_not_found", errorMsg: "users.errors.notFound" },
  {
    status: 413,
    id: "file_too_large",
    errorMsg: "errors.files.uploadFileTooBig",
  },
  {
    status: 415,
    id: "unsupported_media_type",
    errorMsg: "errors.files.uploadFileUnsupported",
  },
  {
    status: 404,
    id: "not_found.page_not_found",
    errorMsg: "errors.customHomePage.customHomePageNotFound",
  },
  {
    status: 404,
    id: "not_found.section_not_found",
    errorMsg: "errors.customHomePage.customHomePageSectionNotFound",
  },
  { status: 404, id: "not_found.user_type_not_found", errorMsg: "users.errors.notFound" },
];

export const timelineEventErrors: HandledError[] = [
  {
    status: 401,
    id: "access_denied",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 404,
    id: "not_found.user_not_found",
    errorMsg: "users.errors.notFound",
  },
  {
    status: 404,
    id: "not_found.course_not_found",
    errorMsg: "errors.courses.notFound",
  },
  {
    status: 404,
    id: "not_found.timeline_entry_not_found",
    errorMsg: "errors.timeline.entryNotFound",
  },
];

export const customReportsErrors: HandledError[] = [
  {
    status: 400,
    id: "validation_error",
    errorMsg: "errors.customReports.validationError",
  },
  {
    status: 401,
    id: "access_denied",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 402,
    id: "payment_required.custom_report_not_supported_by_current_plan",
    errorMsg: "errors.customReports.paymentRequired",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 404,
    id: "not_found.custom_report_not_found",
    errorMsg: "errors.customReports.notFound",
  },
];

export const calendarEventErrors: HandledError[] = [
  ...genericErrors,
  {
    status: 404,
    id: "not_found.group_not_found",
    errorMsg: "groups.courses.errors.groupNotFound",
  },

  {
    status: 404,
    id: "not_found.course_not_found",
    errorMsg: "errors.courses.notFound",
  },
];

export const cancelSubscriptionErrors: HandledError[] = [
  ...genericErrors,
  {
    status: 409,
    id: "conflict",
    errorMsg: "errors.cancelSubscription.alreadyCancelled",
  },
];

export const downgradeErrors: HandledError[] = [
  ...genericErrors,
  {
    status: 409,
    id: "conflict",
    errorMsg: "errors.downgradeToFree.alreadyOnFreePlan",
  },
];

export const reactivateSubscriptionErrors: HandledError[] = [
  ...genericErrors,
  {
    status: 409,
    id: "conflict",
    errorMsg: "errors.cancelSubscription.alreadyReactivated",
  },
];

export const updateBillingAddressErrors: HandledError[] = [...genericErrors];

export const updateCreditCardErrors: HandledError[] = [...genericErrors];

export const markQuestionCorrectErrors: HandledError[] = [
  ...genericErrors,
  {
    status: 409,
    id: "conflict.question_already_correct",
    errorMsg: "errors.setQuestionCorrect.alreadyCorrect",
  },
];

export const purchasePlanErrors: HandledError[] = [
  ...genericErrors,
  {
    status: 400,
    id: "bad_request.invalid_coupon",
    errorMsg: "errors.coupons.couponInvalid",
  },
  {
    status: 409,
    id: "bad_request.coupon_expired",
    errorMsg: "errors.coupons.couponExpired",
  },
  {
    status: 402,
    id: "payment_required.price_changed",
    errorMsg: "errors.purchasePlans.alreadyCorrect",
  },
  {
    status: 403,
    id: "forbidden.billing_address_required",
    errorMsg: "errors.purchasePlans.billingAddress",
  },
  {
    status: 403,
    id: "forbidden.credit_card_required",
    errorMsg: "errors.purchasePlans.creditCard",
  },
  {
    status: 403,
    id: "forbidden.branches_limit_exceeded",
    errorMsg: "errors.purchasePlans.branches",
  },
  {
    status: 403,
    id: "forbidden.courses_limit_exceeded",
    errorMsg: "errors.purchasePlans.courses",
  },
  {
    status: 403,
    id: "forbidden.users_limit_exceeded",
    errorMsg: "errors.purchasePlans.users",
  },
  {
    status: 404,
    id: "not_found.subscription_plan_not_found",
    errorMsg: "errors.purchasePlans.planNotFound",
  },
  { status: 429, id: "too_many_requests", errorMsg: "errors.purchasePlans.tooManyRequests" },
];

export const userActivationErrors: HandledError[] = [
  ...genericErrors,
  {
    status: 400,
    id: "bad_request.invalid_link",
    errorMsg: "errors.activateUser.invalidLink",
  },
  {
    status: 400,
    id: "bad_request.expired_link",
    errorMsg: "errors.activateUser.expiredLink",
  },
];
export const AICreditsErrors: HandledError[] = [...genericErrors];

export const branchCreateUpdateErrors: HandledError[] = [
  ...genericErrors,
  {
    status: 400,
    id: "bad_request.cannot_deactivate_subscription_mode",
    errorMsg: "branches.properties.errors.cannotDeactivateSubscriptionMode",
  },
  {
    status: 403,
    id: "forbidden.reached_plan_max_branches",
    errorMsg: "branches.properties.errors.reachedMaxBranches",
  },
  {
    status: 404,
    id: "not_found.branch_not_found",
    errorMsg: "branches.courses.errors.branchNotFound",
  },
];

export const badgeSetUpdateErrors: HandledError[] = [
  ...genericErrors,
  {
    status: 404,
    id: "not_found.badge_set_not_found",
    errorMsg: "accountAndSettings.gamification.badgeSetNotFound",
  },
];

export const otherProviderCoursePaymentErrors: HandledError[] = [
  ...genericErrors,
  {
    status: 402,
    id: "payment_required",
    errorMsg: "payments.errors.paymentRequired",
  },
  {
    status: 402,
    id: "payment_required.price_changed",
    errorMsg: "payments.errors.priceChanged",
  },
  {
    status: 409,
    id: "conflict.course_is_not_active",
    errorMsg: "errors.units.courseNotActive",
  },
];

export const otherProviderCoursePriceEstimationErrors: HandledError[] = [
  ...genericErrors,
  {
    status: 404,
    id: "not_found.course_not_found",
    errorMsg: "errors.courses.notFound",
  },
];

export const otherProviderCourseEnrollmentErrors: HandledError[] = [
  ...genericErrors,
  {
    status: 404,
    id: "not_found.course_not_found",
    errorMsg: "errors.courses.notFound",
  },
  {
    status: 409,
    id: "conflict.course_is_not_active",
    errorMsg: "errors.units.courseNotActive",
  },
];
