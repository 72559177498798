// Packages or third-party libraries
import { AnyObjectSchema } from "yup";

// Utils
import { groupBy } from "@utils/helpers";
import {
  CoursesOptionsSchemas,
  MandatoryCustomFieldSchema,
  MaxLengthCustomFieldSchema,
  yup,
} from "@utils/validation";

// Types
import { CertificateDuration, Course, CustomField, FormCustomFields } from "types/entities";
import { CoursePrerequisite, ScoreRule } from "types/entities/Courses";
import { CourseCustomFieldWithValue, StringFieldSchema } from "./types";
import { EditCourseData } from "@views/CourseEdit/api";
import {
  CompletionRule,
  CourseRulesEditData,
  CourseRulesFormData,
  LearningPathObj,
  TraversalRule,
} from "@views/CourseEdit/types";

export const getValidationSchema = (
  customFields: CourseCustomFieldWithValue[] | undefined,
): AnyObjectSchema => {
  if (!customFields) return CoursesOptionsSchemas;

  const customFieldsSchema = getCustomFieldSchema(customFields);

  if (customFieldsSchema) {
    return CoursesOptionsSchemas.concat(customFieldsSchema);
  }

  return CoursesOptionsSchemas;
};

export const getDefaultValues = (course: Course, customFields: CustomField[]): EditCourseData => {
  const {
    code = null,
    category,
    price,
    is_hidden_from_catalog = false,
    intro_video,
    custom_fields,
    capacity,
    level,
    status,
    time_limit,
    retain_access_after_completion,
    starts_at,
    expires_at,
    certificate,
    is_locked = false,
    policies,
  } = course;
  const {
    can_update_price = false,
    can_update_level = false,
    can_update_locked = false,
  } = policies ?? {};
  const {
    duration = null,
    duration_type,
    expiration_month,
    expiration_day,
    reassign_when = null,
  } = certificate ?? {};

  const priceAmount = can_update_price ? (price?.amount ? price?.amount : null) : undefined;
  const isYoutubeVideo = intro_video?.type === "youtube";
  // Duration is in seconds, covert it to days
  const durationDays =
    duration_type === CertificateDuration.Custom && duration ? duration / (3600 * 24) : undefined;

  const defaultCustomFields = custom_fields?.reduce<FormCustomFields>(
    (object, { value, id }: CourseCustomFieldWithValue) => {
      const customField = customFields.find((item) => item.id === id);

      if (customField?.type === "dropdown") {
        const isSelected = customField.dropdown_items?.some((item) => item.item === value);
        return { ...object, [id]: isSelected ? value : "" };
      }

      if (customField?.type === "checkbox") {
        return { ...object, [id]: value ?? "off" };
      }

      return { ...object, [id]: value ?? "" };
    },
    {},
  );

  return {
    code,
    price: priceAmount,
    is_hidden_from_catalog,
    intro_video_url: isYoutubeVideo ? intro_video?.url : null,
    custom_fields: defaultCustomFields,
    category: category ? { name: category.name, id: category.id } : null,
    // Availability tab access options
    capacity,
    level: can_update_level ? level : undefined,
    is_active: status === "active",
    // Time options
    time_limit,
    retain_access_after_completion,
    starts_at,
    expires_at,
    // Certificate
    certificate: certificate
      ? {
          id: certificate.id,
          duration_type: certificate.duration_type,
          duration_days: durationDays,
          expiration_day: expiration_day ? expiration_day : undefined,
          expiration_month: expiration_month ? expiration_month : undefined,
          reassign_when,
        }
      : null,
    is_locked: can_update_locked ? is_locked : undefined,
  };
};

export const getCustomFieldSchema = (customFields: CustomField[]): AnyObjectSchema | undefined => {
  if (!customFields) return undefined;
  // Get all mandatory custom fields
  const mandatoryFields = customFields.filter((item) => item.mandatory).map((a) => a.id);

  // Create object with mandatory custom fields validations
  const mandatoryFieldsSchema: StringFieldSchema = mandatoryFields.reduce((schema, field) => {
    schema[field] = MandatoryCustomFieldSchema;
    return schema;
  }, {});

  // Get all fields with max length
  const maxLengthFields = customFields.filter((item) => item.max_length);

  // Create object with max length custom fields validations
  const maxLengthFieldsSchema: StringFieldSchema = maxLengthFields.reduce((schema, field) => {
    if (field.max_length) {
      schema[field.id] = MaxLengthCustomFieldSchema(field.max_length);
    }
    return schema;
  }, {});

  if (mandatoryFields.length || maxLengthFields.length) {
    // Create validation schema for custom fields
    const customFieldValidationSchema = yup.object().shape({
      custom_fields: yup
        .object()
        .shape({ ...mandatoryFieldsSchema })
        .concat(yup.object().shape({ ...maxLengthFieldsSchema })),
    });

    // Return custom validation schema
    return customFieldValidationSchema;
  }

  return undefined;
};

export const mapFormDataToSubmitData = (
  data: EditCourseData,
  customFields?: CustomField[],
): EditCourseData => {
  const submitData = { ...data };
  const { time_limit, expires_at } = submitData;
  const canRetainAccess = Boolean(time_limit || expires_at);

  // Set retain_access_after_completion false, when neither expires_at nor time_limit have value
  if (!canRetainAccess) {
    submitData.retain_access_after_completion = false;
  }

  if (!submitData.custom_fields) return submitData;

  const newCustomFields = customFields?.reduce<FormCustomFields>((acc, { id, name }) => {
    const value = submitData?.custom_fields?.[id];

    // Exists in submit data
    if (value !== undefined) acc[name] = value;

    return acc;
  }, {});

  return { ...submitData, custom_fields: newCustomFields };
};

const getLearningPaths = (prerequisites: CoursePrerequisite[]): LearningPathObj[] => {
  const grouped = groupBy(prerequisites, ({ rule_set }) => rule_set);

  return Object.keys(grouped).reduce((array: LearningPathObj[], key) => {
    const group = grouped[key];

    array.push({
      rule_set: Number(key),
      courses: group.map(({ id }) => id),
    });

    return array;
  }, []);
};

export const getRulesDefaultValues = (course: Course): CourseRulesFormData => {
  const {
    rules: { traversal, completion, score_calculation, prerequisites },
  } = course;
  const completionRule = completion[0]?.rule_type ?? CompletionRule.AllUnits;
  const completionPercentage = completion[0]?.unit_percentage ?? null;
  const completionUnitIds = (completion[0]?.complete_units ?? []).map(
    (completionUnit) => completionUnit.id,
  );
  const scoreRule = score_calculation[0]?.rule_type ?? ScoreRule.AllTestsAndAssignments;
  const scoreUnits = (score_calculation[0]?.score_units ?? []).map(({ unit: { id }, weight }) => ({
    id,
    weight,
  }));
  const learningPaths = getLearningPaths(prerequisites);

  return {
    traversal_rule: traversal as TraversalRule,
    completion_rule: completionRule as CompletionRule,
    completion_percentage: completionPercentage ?? undefined,
    completion_unit_ids: completionUnitIds.length ? completionUnitIds : undefined,
    score_rule: scoreRule,
    score_units: scoreUnits.length ? scoreUnits : undefined,
    learning_paths_obj: learningPaths.length ? learningPaths : undefined,
  };
};

export const mapRulesFormDataToSubmitData = (data: CourseRulesFormData): CourseRulesEditData => {
  const { learning_paths_obj, ...rest } = data;
  const submitData: CourseRulesEditData = rest;

  if (learning_paths_obj && learning_paths_obj.length > 0) {
    submitData.learning_paths = learning_paths_obj.reduce((array: number[][], path) => {
      array.push(path.courses);
      return array;
    }, []);
  }

  return submitData;
};
