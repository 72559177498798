type ValidUrlKeys =
  | "courseId"
  | "unitId"
  | "questionId"
  | "messageId"
  | "messageAction"
  | "discussionId"
  | "replyId"
  | "commentId"
  | "branchId"
  | "userId"
  | "groupId"
  | "term"
  | "activeTab"
  | "publicKey"
  | "unitType"
  | "skillId"
  | "reportId"
  | "drawer"
  | "questionNumber";

export type UrlConfig = { [key in ValidUrlKeys]?: string };
export type LocationState = {
  from: {
    pathname: string;
  };
};

export const URLS = {
  root: "/",
  login: "/login",
  signup: "/signup",
  autologin: "/autologin",
  activateUser: "/activate-user",
  gotocourse: "/gotocourse",
  passwordCreate: "/password-create",
  passwordReset: "/password-reset",
  changePassword: "/password-change",
  dashboard: "/dashboard",
  terms: "/terms-of-service",
  emailVerification: "/verify-email",
  onboardingOptions: "/onboarding",
  onboardingWelcome: "/welcome",
  onboardingNotAvailable: "/onboarding-not-available",

  accountAndSettings: {
    accountAndSettings: "/account",
    customizeTheming: "/account/customize-theming",
    customizeBadges: "/account/customize-badges",
    customDomain: "/account/custom-domain",
    checkSSOConfig: "/account/check-sso-config",
    customUserFields: "/account/custom-user-fields",
    customCourseFields: "/account/custom-course-fields",
    certificates: "/account/certificates",
    invoiceList: "/account/invoices",
    couponsList: "/account/coupons",
  },
  customHomePage: {
    builder: "/account/cms",
    preview: "/account/cms/preview",
    previewSlug: "/account/cms/preview/:pageSlug",
    previewDynamic: (pageSlug: string): string => `/account/cms/preview/${pageSlug}`,
    privateSlug: "/cms/:pageSlug",
    privateDynamic: (pageSlug: string | undefined): string => `/cms/${pageSlug}`,
    public: "/:pageSlug",
    publicSlug: "/:pageSlug",
    publicDynamic: (pageSlug: string): string => `/${pageSlug}`,
    customHomePage: "/account/cms/custom-homepage",
  },
  user: {
    profile: "/my/profile",
    customizeTheming: "/my/profile/customize-theming",
    calendar: "/my/calendar",
    certificates: "/my/certificates",
    files: "/my/files",
    badges: "/my/badges",
    branches: "/my/branches",
    courses: "/my/courses",
    course: "/my/courses/:courseId",
    createCourseLink: ({ courseId }: UrlConfig): string => `/my/courses/${courseId}`,
    createCourseResultsLink: ({ courseId }: UrlConfig): string =>
      `/my/courses/${courseId}/units/completed`,
    unitContinue: "/my/courses/:courseId/units/continue",
    unit: "/my/courses/:courseId/units/:unitId",
    unitPreview: "/my/courses/:courseId/units/:unitId/preview",
    createUnitLink: ({ courseId, unitId }: UrlConfig): string =>
      `/my/courses/${courseId}/units/${unitId}`,
    createPreviewUnitLink: ({ courseId, unitId }: UrlConfig): string =>
      `/my/courses/${courseId}/units/${unitId}/preview`,
    messages: "/my/messages",
    newMessage: "/my/messages/new",
    inboxMessage: "/my/messages/:messageId/inbox",
    createInboxMessageLink: ({ messageId }: UrlConfig, { messageAction }: UrlConfig): string =>
      `/my/messages/${messageId}/inbox${messageAction ? `?messageAction=${messageAction}` : ""}`,
    sentMessage: "/my/messages/:messageId/sent",
    createSentMessageLink: ({ messageId }: UrlConfig, { messageAction }: UrlConfig): string =>
      `/my/messages/${messageId}/sent${messageAction ? `?messageAction=${messageAction}` : ""}`,
    discussions: "/my/discussions",
    newDiscussion: "/my/discussions/new",
    singleDiscussion: "/my/discussions/:discussionId",
    getSingleDiscussionLink: ({ discussionId }: UrlConfig): string =>
      `/my/discussions/${discussionId}`,
    getSingleDiscussionReplyLink: ({ discussionId, replyId }: UrlConfig): string =>
      `/my/discussions/${discussionId}${replyId ? `?replyId=${replyId}` : ""}`,
    getSingleDiscussionCommentLink: ({ discussionId, commentId }: UrlConfig): string =>
      `/my/discussions/${discussionId}?commentId=${commentId}`,
    editDiscussion: "/my/discussions/edit/:discussionId",
    editDiscussionLink: ({ discussionId }: UrlConfig): string =>
      `/my/discussions/edit/${discussionId}`,
    groups: "/my/groups",
    payments: "/my/payments",
    progress: "/my/progress",
  },
  catalog: {
    index: "/catalog",
    course: "/catalog/courses/:courseId",
    courseFiles: "/catalog/courses/:courseId/files",
    createCourseLink: ({ courseId }: UrlConfig): string => `/catalog/courses/${courseId}`,
    createCatalogCourseFilesLink: ({ courseId }: UrlConfig): string =>
      `/catalog/courses/${courseId}/files`,
    sampleUnit: "/catalog/courses/:courseId/units/:unitId",
    createSampleUnitLink: ({ courseId, unitId }: UrlConfig): string =>
      `/catalog/courses/${courseId}/units/${unitId}`,
    adminCourse: "admin/catalog/courses/:courseId",
    adminCourseOverviewLink: ({ courseId }: UrlConfig): string =>
      `/admin/catalog/courses/${courseId}`,
  },
  search: {
    all: "/search/all",
    getSearchAllLink: ({ term }: UrlConfig): string => `/search/all/?term=${term}`,
    courses: "/search/courses",
    users: "/search/users",
    files: "/search/files",
  },
  courses: {
    courses: "/courses",
    users: "/courses/:courseId/users",
    edit: "/courses/:courseId/edit",
    singleCourse: "/courses/:courseId",
    getCourseEditLink: ({ courseId }: UrlConfig): string => `/courses/${courseId}/edit`,
    getSingleCourse: ({ courseId, activeTab }: UrlConfig): string =>
      `/courses/${courseId}${activeTab ? `?activeTab=${activeTab}` : ""}`,
    courseShare: "/courses/:courseId/share",
    getCourseShareLink: ({ courseId }: UrlConfig): string => `/courses/${courseId}/share`,
  },
  units: {
    edit: "/courses/:courseId/units/:unitId/edit",
    getUnitEditLink: ({ courseId, unitId }: UrlConfig): string =>
      `/courses/${courseId}/units/${unitId}/edit`,
    singleUnitReports: "/courses/:courseId/units/:unitId",
    getSingleUnitReports: ({ courseId, unitId }: UrlConfig): string =>
      `/courses/${courseId}/units/${unitId}`,
  },
  branches: {
    branches: "/branches",
    branch: "/branches/:branchId",
    create: "/branches/create",
    checkSSOConfig: "/branches/:branchId/check-sso-config",
    getCheckSSOConfig: ({ branchId }: UrlConfig): string =>
      `/branches/${branchId}/check-sso-config`,
    getBranchLink: ({ branchId }: UrlConfig): string => `/branches/${branchId}`,
    edit: "/branches/:branchId/edit",
  },
  groups: {
    create: "/groups/create",
    groups: "/groups",
    group: "groups/:groupId",
    getGroupLink: ({ groupId }: UrlConfig): string => `/groups/${groupId}`,
    getGroupEditLink: ({ groupId }: UrlConfig): string => `/groups/${groupId}/edit`,
    edit: "/groups/:groupId/edit",
  },
  categories: {
    categories: "/categories",
  },
  automations: {
    automations: "/automations",
  },
  notifications: {
    notifications: "/notifications",
    notificationHistory: "/notifications/sent",
    notificationHistoryItem: "notifications/sent/:notificationHistoryId",
    resend: "/notifications/sent/:notificationHistoryId/resend",
    notificationsPending: "/notifications/pending",
    notificationPendingItem: "notifications/pending/:notificationPendingId",
    systemNotification: "/notifications/system",
  },
  users: {
    users: "/users",
    create: "/users/create",
    user: "users/:userId",
    getUserLink: ({ userId }: UrlConfig): string => `/users/${userId}`,
    getEditLink: ({ userId }: UrlConfig): string => `/users/${userId}/edit`,
    edit: "/users/:userId/edit",
  },
  referral: {
    referral: "/referral",
  },
  reports: {
    reports: "/reports",
    courseReports: "/reports/courses",
    userReports: "/reports/users",
    course: "/reports/courses/:courseId",
    getCourseLink: ({ courseId }: UrlConfig): string => `/reports/courses/${courseId}`,
    user: "/reports/users/:userId",
    getUserLink: ({ userId }: UrlConfig): string => `/reports/users/${userId}`,
    groupReports: "/reports/groups",
    branchReports: "/reports/branches",
    branch: "/reports/branches/:branchId",
    getBranchLink: ({ branchId }: UrlConfig): string => `/reports/branches/${branchId}`,
    group: "/reports/groups/:groupId",
    getGroupLink: ({ groupId }: UrlConfig): string => `/reports/groups/${groupId}`,
    learningActivities: "/reports/learning-activities",
    units: {
      unit: "/reports/learning-activities/:type/:unitId",
      testUnit: "/reports/learning-activities/test/:unitId",
      scormUnit: "/reports/learning-activities/scorm/:unitId",
      iltUnit: "/reports/learning-activities/ilt/:unitId",
      assignmentUnit: "/reports/learning-activities/assignment/:unitId",
      surveyUnit: "/reports/learning-activities/survey/:unitId",
    },
    getLearningActivityLink: ({ unitType, unitId }: UrlConfig): string =>
      `/reports/learning-activities/${unitType}/${unitId}`,
    customReports: "/reports/custom",
    customReport: "/reports/custom/:reportId",
    getCustomReportLink: ({ reportId }: UrlConfig): string => `/reports/custom/${reportId}`,
    userSubmissionAssignment: `/reports/assignments/attempts`,
    userSubmissionSurvey: `/reports/surveys/attempts`,
    userSubmissionTest: `/reports/tests/attempts`,
  },
  gradingHub: {
    gradingHub: "/gradingHub",
  },
  publicCourse: {
    index: "/courses/public/:courseId",
    unit: "/courses/public/:courseId/units/:unitId",
    publicCourseNavigator: "/courses/shared/start/:publicKey",
    getPublicCourseLink: ({ courseId }: UrlConfig): string => `/courses/public/${courseId}`,
    getNavigatorUrl: ({ publicKey }: UrlConfig): string => `/courses/shared/start/${publicKey}`,
    createPublicUnitLink: ({ courseId, unitId }: UrlConfig): string =>
      `/courses/public/${courseId}/units/${unitId}`,
    createCourseResultsLink: ({ courseId }: UrlConfig): string =>
      `/courses/public/${courseId}/units/completed`,

    sampleUnit: "/courses/public/:courseId/units/sample/:unitId",
    createSampleUnitLink: ({ courseId, unitId }: UrlConfig): string =>
      `/courses/public/${courseId}/units/sample/${unitId}`,
  },
  courseStore: {
    index: "/course-store",
    course: "/course-store/:courseId",
    otherProvidersCourse: "/course-store/other-providers/:courseId",
    createCourseLink: ({ courseId }: UrlConfig): string => `/course-store/${courseId}`,
    createOtherProviderCourseLink: ({ courseId }: UrlConfig): string =>
      `/course-store/other-providers/${courseId}`,
  },
  subscription: {
    subscription: "/subscription",
    subscriptionOptions: "/subscription/options",
  },
  conferences: {
    index: "/conferences",
  },
  skills: {
    skills: "/skills",
    getSkillsLink: ({ activeTab }: UrlConfig) => `/skills${activeTab ? `?tab=${activeTab}` : ""}`,
    mySkills: "/mySkills",
    getMySkillsLink: ({ activeTab }: UrlConfig) =>
      `/mySkills${activeTab ? `?tab=${activeTab}` : ""}`,
    myJobRole: "/mySkills/myJobRole/:jobRole",
    getMyJobRoleLink: (jobRoleObfuscated: string): string =>
      `/mySkills/myJobRole/${jobRoleObfuscated}`,
    mySkill: "/mySkills/skill",
    getMySkillLink: ({ skillId, activeTab, drawer, courseId }: UrlConfig): string =>
      `/mySkills/skill?id=${skillId}&tab=${activeTab}${drawer !== undefined ? "&drawer=true" : ""}${courseId !== undefined ? `&courseId=${courseId}` : ""}`,
    jobRole: "/skills/jobRole/:jobRole",
    getJobRoleLink: (jobRoleObfuscated: string): string => `/skills/jobRole/${jobRoleObfuscated}`,
    editSkill: "/skills/edit/:skillId",
    getEditSkillLink: ({ skillId }: UrlConfig): string => `/skills/edit/${skillId}`,
    skillAssessment: "/skills/:skillId/assessment",
    getSkillAssessmentLink: ({ skillId }: UrlConfig): string => `/skills/${skillId}/assessment`,
    skillAssessmentQuestion: "/skills/:skillId/assessment/questions/:questionNumber",
    getSkillAssessmentQuestionLink: ({ skillId, questionNumber }: UrlConfig) =>
      `/skills/${skillId}/assessment/questions/${questionNumber}`,
    editSkillAssessment: `/skills/edit/:skillId/assessment`,
    getEditSkillAssessmentLink: ({ skillId }: UrlConfig): string =>
      `/skills/edit/${skillId}/assessment`,
    skillAssessmentResults: "/skills/:skillId/assessment/results",
    getSkillAssessmentResults: ({ skillId }: UrlConfig): string =>
      `/skills/${skillId}/assessment/results`,
  },
} as const;

export const rootURLS = [
  URLS.subscription.subscription,
  URLS.reports.learningActivities,
  URLS.reports.branchReports,
  URLS.reports.courseReports,
  URLS.reports.userReports,
  URLS.reports.groupReports,
  URLS.reports.courseReports,
  URLS.reports.customReports,
  URLS.accountAndSettings.accountAndSettings,
  URLS.courses.courses,
  URLS.skills.skills,
  URLS.skills.mySkills,
  URLS.users.users,
  URLS.groups.groups,
  URLS.branches.branches,
  URLS.categories.categories,
  URLS.reports.reports,
  URLS.dashboard,
  URLS.user.badges,
  URLS.user.files,
  URLS.user.calendar,
  URLS.user.payments,
  URLS.user.profile,
  URLS.user.groups,
  URLS.user.messages,
  URLS.user.discussions,
  URLS.user.courses,
  URLS.catalog.index,
  URLS.user.newMessage,
  URLS.user.inboxMessage,
  URLS.user.sentMessage,
  URLS.user.newDiscussion,
  URLS.user.editDiscussion,
  URLS.user.singleDiscussion,
  URLS.referral.referral,
  URLS.automations.automations,
  URLS.notifications.notifications,
  URLS.courseStore.index,
  URLS.gradingHub.gradingHub,
  URLS.conferences.index,
];

export const profileURLS = [
  URLS.user.badges,
  URLS.user.files,
  URLS.user.payments,
  URLS.user.profile,
  URLS.user.groups,
];

export const secondaryURLS = [
  URLS.user.messages,
  URLS.user.newMessage,
  URLS.user.discussions,
  URLS.user.newDiscussion,
  URLS.user.singleDiscussion,
  URLS.user.editDiscussion,
  URLS.user.inboxMessage,
  URLS.user.sentMessage,
];

export const eventsEngineURLS = [URLS.automations.automations, URLS.notifications.notifications];

// URLS that only a learner can access
export const learnerURLS: string[] = [
  URLS.user.courses,
  URLS.user.course,
  URLS.catalog.index,
  URLS.catalog.course,
  URLS.user.unit,
  URLS.catalog.sampleUnit,
  URLS.user.unitContinue,
  URLS.user.progress,
  URLS.user.badges,
];
