// Packages or third-party libraries
import React, { FC } from "react";
import { Tooltip } from "@epignosis_llc/gnosis";
import { AxiosError } from "axios";
import { useQuery } from "react-query";

// Styles
import { AIButtonContainerStyles, AIButtonStyles } from "./styles";

// Utils, hooks
import { handleAICreditsErrors } from "@errors/errors";
import { useApplyTranslations } from "@hooks";

// Other imports
import { getCraftCredits } from "../AICreditsDrawer/api";
import queryKeys from "@constants/queryKeys";
import { AIIconSVG } from "@assets/images";

type AIButtonProps = {
  onClick: () => void;
};

const AIButton: FC<AIButtonProps> = ({ onClick }) => {
  const { t } = useApplyTranslations();

  const { data: craftCredits } = useQuery([queryKeys.craft.credits], getCraftCredits, {
    select: (res) => res._data,
    onError: (error: AxiosError) => {
      handleAICreditsErrors(error);
    },
  });

  const { ai_credits: balance } = craftCredits ?? {};

  return (
    <div css={AIButtonContainerStyles}>
      <Tooltip
        content={t("AICredits.AICreditBalanceTooltip", { count: balance ?? 0 })}
        parentProps={{ "aria-label": t("a11y.units.openAICreditsDrawer") }}
        placement="auto-end"
      >
        <div css={AIButtonStyles} onClick={onClick}>
          <AIIconSVG height={40} />
        </div>
      </Tooltip>
    </div>
  );
};

export default AIButton;
